import React from 'react'
import Layout from '../components/Layout'
import { Box, Heading, Text } from '../lib/design-system'

const NotFoundPage = () => (
  <Layout>
    <Box p={4}>
      <Heading as="h1" mb={3}>NOT FOUND</Heading>
      <Text>You just hit a route that doesn't exist</Text>
    </Box>
  </Layout>
)

export default NotFoundPage
